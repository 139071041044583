function BaseAPI() {
    let base_url = "https://hanhphucngotngao.com/";
    return {
        BASE_URL : base_url,
        Get: (path, onsuccess) => {
            fetch(base_url + path).then(res => res.json()).then(data => onsuccess(data));
        }
    }
}

export default BaseAPI;