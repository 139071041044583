import { useEffect } from "react";
import GuestAPI from './Models/GuestAPI';

function Modal(props) {
    useEffect(() => {

    }, []);

    const saveConfirm = () => {
        GuestAPI().confirm(props.data.qrCode, () => {
			props.setShow(false);
		})
	}

    return (<div id="myModal" class="modal" style={{ display: (props.isShow ? "block" : "none") }}>
        <img class="modal-img" src={process.env.REACT_APP_REACT_APP_SERVER_URL +  "/static/media/wedding_backgroud_wellcome.png"} alt=""></img>
        <div class="modal-content">
            {/* <span class="close">&times;</span> */}
            <div style={{ textAlign: "center" }}>
                <h3 style={{ fontFamily: "Great Vibes", fontSize: "35px" }}>Hôn Lễ Quốc Huy & Kim Quyên</h3>
                <h4 style={{ fontSize: "30px" }}>Chào mừng</h4>
                <h1 style={{ fontSize: "40px" }}>{props.data?.name ?? ""}</h1>
                <p style={{ fontSize: "30px" }}>Sự có mặt của Quí Khách là niềm vinh hạnh cho gia đình!!!</p>
            </div>
            <div style={{textAlign: "center"}}>
                <button class="btn-thamdu" onClick={saveConfirm}>Tham dự</button>
            </div>
        </div>
    </div>);
}

export default Modal;