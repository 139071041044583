import BaseAPI from "./BaseAPI";

function getGuest(qrCode, onsuccess) {
    BaseAPI().Get("guest?qrCode=" + qrCode, data => {
        onsuccess(data);
    }, error => {
        console.log(error)
    })
}

function confirm(qrCode, onsuccess) {
    BaseAPI().Get("guest/confirm?qrCode=" + qrCode, data => {
        onsuccess(data);
    }, error => {
        console.log(error)
    })
}

function xacNhanThamDu(onsuccess) {
    BaseAPI().Get("guest/xac-nhan-tham-du", data => {
        onsuccess();
    }, error => {
        console.log(error)
    })
}

function GuestAPI() {
    return {
        getGuest: getGuest,
        confirm: confirm,
        xacNhanThamDu: xacNhanThamDu
    }
}

export default GuestAPI;